import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { GoogleApiIntegrationService } from '../google-api-integration.service';
import * as $ from 'jquery';
import { PwDropdownService, PwFirebaseService, PwThemeingService, PwThemeModel } from '@kaleva-media/purple-water-next';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NationalitiesService } from '../Services/nationalities.service';

@Component({
  selector: 'african-bank-transactional',
  templateUrl: './african-bank-transactional.component.html',
  styleUrls: ['./african-bank-transactional.component.scss']
})
export class AfricanBankTransactionalComponent implements OnInit {
  agentData: any;
  campaignType: any;
  isSuccess: boolean = false;
  isError: boolean = false;
  message: string = '';
  leadForm: FormGroup;
  formStatus: any = {
    formErrors: {},
    submitClicked: false
  }
  validationMessages: any;
  errorCount: number;
  error: boolean;
  showMessage: boolean;
  API_URL = environment.afbSubmitterUrl;
  success: boolean = false;
  commentsDefaultValue: string = 'Positive response, First for Women';
  _theme: PwThemeModel;
  birthDate: string;
  isPassport: boolean = false;
  PRODUCT_CODE: string = 'FUN';

  constructor(
    private formBuilder: FormBuilder,
    private googleApiIntegrationService: GoogleApiIntegrationService,
    private firebase: PwFirebaseService,
    private theme: PwThemeingService,
    private snackBar: MatSnackBar,
    private dropdownService: PwDropdownService,
    private nationalites: NationalitiesService) {


    this.agentData = {
      name: 'agentName',
      label: 'Agent Name:',
      options: [],
      placeholder: 'Select Name'
    };
  }

  ngOnInit() {
    this.createForm();

    this.googleApiIntegrationService.reatAtNames();
    this.googleApiIntegrationService.names.subscribe((names) => {
      let namesArray = JSON.parse(names);
      namesArray.map((nameArray, index) => {
        this.agentData.options[index] = {name: 'agent-' + index, label: nameArray[0]};
      });
    });

    this._theme = new PwThemeModel({
      borderColor: '#032B60',
      textColor: '#000',
      buttonBgColor: '#032B60',
      buttonTextColor: '#fff',
      buttonBorderColor: '#032B60',
      errorColor: 'red',
      labelColor: '#000',
      dropdownOptionBgColor: '#032B60',
      dropdownOptionTextColor: '#fff'
    })
    this.theme.setTheme(this._theme);
  }

  createForm() {
    this.leadForm = this.formBuilder.group({
      name: ['', Validators.required],
      telephone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      agentName: '',
      surname: ['', Validators.required],
      idNumber: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(13)]],
      email: ['', [Validators.required, Validators.email]],
    });

    this.validationMessages = {
      email: {
        'required': 'Please enter a valid email',
        'email': 'Please enter a valid email'
      },
      name: {
        'required': 'Please enter a valid name'
      },
      surname: {
        'required': 'Please enter a valid surnname'
      },
      telephone: {
        'required': 'Please enter a valid number',
        'maxlength': 'Please enter a number not greater than 10 charaters',
        'minlength': 'Please enter a number not less than 10 charaters'
      },
      idNumber: {
        'required': 'Please enter a valid ID number',
        'maxlength': 'Please enter a number not greater than 13 charaters',
        'minlength': 'Please enter a number not less than 13 charaters'
      }
    }

    this.formStatus['formErrors'] = {
      name: '',
      email: '',
      surname: '',
      telephone: '',
      idNumber: '',
    }

    this.leadForm.valueChanges.subscribe((data) => {
      this.formIsValid();
    });
  }

  submitLead() {
    if (this.leadForm.status !== 'VALID') {
      this.formStatus.submitClicked = true;
      this.formIsValid();
      return;
    }

    let name = this.leadForm.controls['name'].value;
    let surname = this.leadForm.controls['surname'].value;
    let telephone = this.leadForm.controls['telephone'].value;
    let email = this.leadForm.controls['email'].value;
    let idNumber = this.leadForm.controls['idNumber'].value;
    let agentName = this.leadForm.controls['agentName'].value;
    let campaignName = 'africanBank';

    this.firebase.saveRecord({
      id: telephone,
      email: email,
      cellNumber: telephone,
      firstName: name,
      iDNumber: idNumber,
      surname: surname,
      agentName: agentName.label,
      campaignName: campaignName,
      date: this.getOptinDate(),
      day: this.getOptinDateObj().day,
      month: this.getOptinDateObj().month,
      year: this.getOptinDateObj().year
    }, 'leads').then(() => {
      this.firebase.updateCount('leads', campaignName).then(() => {
        this.firebase.updateCount('leads');
      });
    });

    $.post(this.API_URL, {
      Email: email,
      CellNumber: telephone,
      FirstName: name,
      IDNumber: idNumber,
      Initials: name.substring(0, 1),
      Surname: surname,
      ProductCode: this.PRODUCT_CODE
    }, (response, status) => {
      this.error = true;
      this.showMessage = true;

      console.log(response);

      const jsonResp = JSON.parse(response);
      let maximumCapital: any, maximumCreditInterestRate: any, maximumDebitInterestRate: any, maximumTerm: any, type: any

      if (jsonResp.data) {
        if (jsonResp.data.length > 0) {
          maximumCapital = jsonResp.data[0].maximumCapital;
          maximumCreditInterestRate = jsonResp.data[0].maximumCreditInterestRate;
          maximumDebitInterestRate = jsonResp.data[0].maximumDebitInterestRate;
          maximumTerm = jsonResp.data[0].maximumTerm;
          type = jsonResp.data[0].type;
          this.message = "Lead submitted successfully.";
        }
      } else {
        if (jsonResp.hasOwnProperty('errors')) {
          this.message = jsonResp.errors[0].message + ' ' + jsonResp.errors[0].path;
        }
      }

      if (status === "success") {
        this.googleApiIntegrationService.writeToAfricanBankSheet({
          Email: email,
          CellNumber: telephone,
          maximumCapital: maximumCapital ? maximumCapital : 'Error',
          maximumCreditInterestRate: maximumCreditInterestRate ? maximumCreditInterestRate : 'Error',
          maximumDebitInterestRate: maximumDebitInterestRate ? maximumDebitInterestRate : 'Error',
          maximumTerm: maximumTerm ? maximumTerm : 'Error',
          type: type ? type : 'Error',
          FirstName: name,
          IDNumber: idNumber,
          Surname: surname,
          status: this.message,
          agentName: agentName.label,
        });

        this.snackBar.open(this.message, 'Ok!', {
          verticalPosition: "bottom", // Allowed values are  'top' | 'bottom'
          horizontalPosition: "center" // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
        });

        this.resetForm();
      } else {
        if (status === "error") {
          this.message = "Oops, an error has occured. Please try again."
          this.isError = true;
          this.isSuccess = false;

          this.googleApiIntegrationService.writeToAfricanBankSheet({
            Email: email,
            CellNumber: telephone,
            maximumCapital: maximumCapital ? maximumCapital : '',
            maximumCreditInterestRate: maximumCreditInterestRate ? maximumCreditInterestRate : '',
            maximumDebitInterestRate: maximumDebitInterestRate ? maximumDebitInterestRate : '',
            maximumTerm: maximumTerm ? maximumTerm : '',
            type: type ? type : '',
            FirstName: name,
            IDNumber: idNumber,
            Surname: surname,
            status: 'Not Submitted',
            agentName: agentName.label,
          });
        }
      }
    }, "html");
  }

  resetForm() {
    this.dropdownService.resetDropdown('agentName');
    this.leadForm.reset();
  }

  formIsValid() {
    if (!this.leadForm) {
      return;
    }

    const form = this.leadForm;
    this.errorCount = 0;

    // tslint:disable-next-line:forin
    for (const field in this.formStatus['formErrors']) {
      // clear previous error message (if any)
      this.formStatus['formErrors'][field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        // tslint:disable-next-line:forin

        for (const key in control.errors) {

          this.formStatus['formErrors'][field] += messages[key] + ' ';

          this.errorCount++;
        }

      }
    }
  }

  sanitizeString(str){
    str = str.replace(/[^a-z0-9áéíóúñü \.,_-]/gim,"");
    return str.trim();
  }

  getOptinDate() : string {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    let strDate = dd + '/' + mm + '/' + yyyy;
    return strDate;
  }

  getOptinDateObj() : any {
    var today = new Date();
    let dateObj = {};
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    let strDate = dd + '/' + mm + '/' + yyyy;
    dateObj['day'] = dd;
    dateObj['month'] = mm;
    dateObj['year'] = yyyy;
    dateObj['date'] = strDate;
    return dateObj;
  }
}
