<div class="submit-lead">
  <div class="header">
    <img src="../../assets/african-bank.jpeg"/>
  </div>

  <div class="body" [ngClass]="{'flip': success, 'back': success}">
    <div *ngIf="!success">

      <pw-input [data]="{name: 'name', label: 'Name:', type: 'text'}" [parentFormGroup]="leadForm" [formStatus]="formStatus"></pw-input>

      <pw-input [data]="{name: 'surname', label: 'Surname:', type: 'text'}" [parentFormGroup]="leadForm" [formStatus]="formStatus"></pw-input>

      <pw-input [data]="{name: 'telephone', label: 'Cellphone:', type: 'number'}" [parentFormGroup]="leadForm" [formStatus]="formStatus"></pw-input>

      <pw-input [data]="{name: 'email', label: 'Email:', type: 'email'}" [parentFormGroup]="leadForm" [formStatus]="formStatus"></pw-input>

      <pw-input [data]="{name: 'idNumber', label: 'ID Number:', type: 'number'}" [parentFormGroup]="leadForm" [formStatus]="formStatus"></pw-input>

      <pw-dropdown [data]="agentData" [parentFormGroup]="leadForm"></pw-dropdown>

      <pw-button [data]="{name: 'submit', label: 'Submit', disabled: false}" (click)="submitLead()"></pw-button>
    </div>

    <div class="reset" *ngIf="success" >
      <pw-button [data]="{name: 'ok', label: 'Ok', disabled: false}" (click)="resetForm()"></pw-button>
    </div>

    <div class="back message" *ngIf="success">
      <div class="message__heading">
        Lead Submitted
      </div>

      <div class="message__message">
        Your details have been successfully submitted. An assistant will contact you shortly. To help speed up the process, have your ID ready.
      </div>
    </div>
  </div>

  <div class="footer">
    &copy; Copyright 2023 Kaleva Media (PTY) Ltd.
  </div>
</div>
