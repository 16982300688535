export const environment = {
  production: true,
  afbApiUrl: 'https://secure.api.stg.africanbank.co.za',
  afbSubmitterUrl: 'https://secure.api.stg.africanbank.co.za',
  africanBankWriterApi: 'https://mts.motloungtech.africa/Writers/africanBankWriter.php',
  africanBankFuneralWriterApi: 'https://mts.motloungtech.africa/Writers/africanBankFuneralWriter.php',
  africanBankInvestmentsWriterApi: 'https://mts.motloungtech.africa/Writers/africanBankInvestmentsWriter.php',
  africanBankTransactionalWriterApi: 'https://mts.motloungtech.africa/Writers/africanBankTransactionalWriter.php',
  apiUrl: 'https://repository.isservices.co.za/bannerprocessing/process.aspx',
  apiUrlAllLife: 'https://leads.alllife.co.za:8443/magnum/direct_api/submit_lead',
  apiUrl_hollard: 'http://fullserve.osmdialnow.co.za/vicidial/non_agent_api.php',
  localeApi: 'https://www.jeffasenmusic.co.za/api_caller.php',
  apiUrl_kingprice: 'http://ws.kingprice.co.za/prews/WebServices/Cavalry/Outbound/LeadsService.asmx?wdsl',
  apiUrl_budget: 'https://services.quickinsure.co.za/api/lead',
  apiUrl_carloan: 'https://returnxdigital.leadbyte.co.uk/integration?slice=5e1d6717da842669799576',
  apiUrl_sanlam: 'http://tracking.affcoza.com/aff_c?offer_id=2072&aff_id=18897',
  crmApiUrl: 'https://luhambomarketing.co.za/Luhambo-CRM/apis/db-api.php',
  kingPriceWriterApi: 'https://mts.motloungtech.africa/Writers/kingPriceSheetWriter.php',
  kingPriceSubmitterApi: 'https://mts.motloungtech.africa/Submitters/submit-kingprice-lead.php',
  readNamesApi: 'https://mts.motloungtech.africa/Readers/at-readnames.php',
  lifeWriterApi: 'https://mts.motloungtech.africa/Writers/atLifeWriter.php',
  autoAndGeneralWriterApi: 'https://mts.motloungtech.africa/Writers/autoAndGeneralWriter.php',
  budgetInsuranceWriterApi: 'https://mts.motloungtech.africa/Writers/budgetInsuranceWriter.php',
  firstForWomenWriterApi: 'https://mts.motloungtech.africa/Writers/firstForWomenWriter.php',
  dialDirectWriterApi: 'https://mts.motloungtech.africa/Writers/dialDirectWriter.php',
  readerApi: 'http://gilela.kalevamedia.com/apis/at-readnames.php',
  tracker: {
    trackerUsername: 'Gilela',
    trackerPassword: 'Tracker@112#',
    sourceId: '91459',
    trackerSubmitterApi: 'http://gilela.kalevamedia.com/apis/tracker-submitter.php',
    trackerWriterApi: 'http://gilela.kalevamedia.com/apis/tracker-writer.php'
  },
  firebaseConfig: {
    apiKey: "AIzaSyBxNAQT_aDQoclNDd-mORuSoJqmN0Rlzcg",
    authDomain: "betterlife-404313.firebaseapp.com",
    projectId: "betterlife-404313",
    storageBucket: "betterlife-404313.appspot.com",
    messagingSenderId: "472978590089",
    appId: "1:472978590089:web:a44fdd902101c0d18e3958",
    measurementId: "G-9BG379Y4S2"
  },
  campaigns: [{
    name: 'virseker',
    title: 'Virseker',
    type: 'custom', //affiliate || telesure || custom
    path: 'virseker',
    imgSrc: '',
    campaignMeta: {
      url: 'https://repository.isservices.co.za/bannerprocessing/process.aspx',
      currentDate: {
        set: false,
        apiKey: 'date'
      },
      dateOfBirth: {
        set: false,
        apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
      }
    },
    integrationDocUrl: '',
    apiUrl: 'https://mts.motloungtech.africa/api/submitter.php', // see readme to run light-web-server, this will help you run your php locally
    inputs: [
      {
        name: 'name',
        label: 'Firstname',
        type: 'input',
        inputType: 'text',
        placeholder: '',
        validations: [
          {
            validation: 'required',
            message: 'Please enter valid Firstname'
          }
        ]
      },
      {
        name: 'surname',
        label: 'Surname',
        type: 'input',
        inputType: 'text',
        placeholder: '',
        validations: [
          {
            validation: 'required',
            message: 'Please enter valid Surname'
          }
        ]
      },
      {
        name: 'tel',
        label: 'Phone Number',
        type: 'input',
        inputType: 'number',
        placeholder: '',
        validations: [
          {
            validation: 'required',
            message: 'Please enter valid Phone Number'
          },
          {
            validation: 'minlength',
            message: 'Please enter valid Phone Number'
          },
          {
            validation: 'maxlength',
            message: 'Please enter valid Phone Number'
          }
        ]
      },
      {
        name: 'vdn',
        label: 'Campaign Type',
        type: 'dropdown',
        inputType: '',
        placeholder: '',
        options: [
          {name: 'WVATW', label: 'Warmup', value: 'WVATW'}
        ],
        validations: [
        ]
      },
      {
        name: 'agentName',
        label: 'Agent Name:',
        type: 'dropdown',
        inputType: '',
        placeholder: '',
        options: [
        ],
        validations: [
        ]
      }
    ],
    sheetConfig: {
      applicationName: 'Betterlife',
      spreadSheetId: '1MZ9uHdz1Y91ccGRhuU877b91JLULBfJCragVtdcRPJI',
      developerKey: 'AIzaSyDdhVwwWS9eLbMiUhePl3MbixG-8oxx8wI',
      writer: {
        sheetName: 'Virseker',
        url: 'https://betterlife.motloungtech.africa/Writers/writer-template.php'
      },
      reader: {
        sheetName: 'agent-names',
        url: 'https://betterlife.motloungtech.africa/Readers/readnames-template.php',
        set: true
      }
    }
  }]
};
