// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  afbApiUrl: 'https://secure.api.stg.africanbank.co.za',
  afbSubmitterUrl: 'https://mts.motloungtech.africa/Submitters/african-bank-submitter.php',
  africanBankWriterApi: 'https://mts.motloungtech.africa/Writers/africanBankWriter.php',
  africanBankFuneralWriterApi: 'https://mts.motloungtech.africa/Writers/africanBankFuneralWriter.php',
  africanBankInvestmentsWriterApi: 'https://mts.motloungtech.africa/Writers/africanBankInvestmentsWriter.php',
  africanBankTransactionalWriterApi: 'https://mts.motloungtech.africa/Writers/africanBankTransactionalWriter.php',
  apiUrl: 'https://repository.isservices.co.za/bannerprocessing/process.aspx',
  crmApiUrl: 'https://luhambomarketing.co.za/Luhambo-CRM-dev/apis/db-api.php',
  tracker: {
    trackerUsername: 'Gilela',
    trackerPassword: 'Tracker@112#',
    sourceId: '55387',
    trackerSubmitterApi: 'http://ndwfinancialservices.co.za/apis/tracker-submitter.php',
    trackerWriterApi: 'http://ndwfinancialservices.co.za/apis/tracker-writer.php'
  },
  readerApi: 'https://mts.motloungtech.africa/Readers/at-readnames.php',
  lifeWriterApi: 'https://mts.motloungtech.africa/Writers/atLifeWriter.php',
  dialDirectWriterApi: 'https://mts.motloungtech.africa/Writers/dialDirectWriter.php',
  firstForWomenWriterApi: 'https://mts.motloungtech.africa/Writers/firstForWomenWriter.php',
  kingPriceWriterApi: 'https://mts.motloungtech.africa/Writers/kingPriceSheetWriter.php',
  kingPriceSubmitterApi: 'https://ndwfinancialservices.co.za/submitters/submit-kingprice-lead.php',
  readNamesApi: 'https://mts.motloungtech.africa/readers/at-readnames.php',
  autoAndGeneralWriterApi: 'https://mts.motloungtech.africa/Writers/autoAndGeneralWriter.php',
  budgetInsuranceWriterApi: 'https://mts.motloungtech.africa/Writers/budgetInsuranceWriter.php',
  firebaseConfig: {
    apiKey: "AIzaSyDGkonFd0xLxdQbNDA3oPn1I6AOowtPB6w",
    authDomain: "mbungela-training-and-skills.firebaseapp.com",
    projectId: "mbungela-training-and-skills",
    storageBucket: "mbungela-training-and-skills.appspot.com",
    messagingSenderId: "771115239707",
    appId: "1:771115239707:web:a41ea344bbb48a03e12bbe",
    measurementId: "G-7KEHXXPPNW"
  },
  cartrack: {
    token: 'f8fd084a-7e88-4c09-b8e7-2b080bfd93d1',
    campaignUid: '3ea9b287-bf99-4582-a625-44dc411a37e8',
    apiUrl: 'https://ndwfinancialservices.co.za/leads-application-uat/Submitters/car-track-submitter.php',
    writerUrl: 'https://ndwfinancialservices.co.za/leads-application-uat/Writers/fvCarTrackSheetWriter.php',
    readUrl: 'https://ndwfinancialservices.co.za/leads-application-uat/Readers/readnames.php'
  },
  campaigns: [{
    name: 'virseker',
    title: 'Virseker',
    type: 'custom', //affiliate || telesure || custom
    path: 'virseker',
    imgSrc: '',
    campaignMeta: {
      url: 'https://repository.isservices.co.za/bannerprocessing/process.aspx',
      currentDate: {
        set: false,
        apiKey: 'date'
      },
      dateOfBirth: {
        set: false,
        apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
      }
    },
    integrationDocUrl: '',
    apiUrl: 'https://repository.isservices.co.za/bannerprocessing/process.aspx', // see readme to run light-web-server, this will help you run your php locally
    inputs: [
      {
        name: 'name',
        label: 'Firstname',
        type: 'input',
        inputType: 'text',
        placeholder: '',
        validations: [
          {
            validation: 'required',
            message: 'Please enter valid Firstname'
          }
        ]
      },
      {
        name: 'surname',
        label: 'Surname',
        type: 'input',
        inputType: 'text',
        placeholder: '',
        validations: [
          {
            validation: 'required',
            message: 'Please enter valid Surname'
          }
        ]
      },
      {
        name: 'tel',
        label: 'Phone Number',
        type: 'input',
        inputType: 'number',
        placeholder: '',
        validations: [
          {
            validation: 'required',
            message: 'Please enter valid Phone Number'
          },
          {
            validation: 'minlength',
            message: 'Please enter valid Phone Number'
          },
          {
            validation: 'maxlength',
            message: 'Please enter valid Phone Number'
          }
        ]
      },
      {
        name: 'vdn',
        label: 'Campaign Type',
        type: 'dropdown',
        inputType: '',
        placeholder: '',
        options: [
          {name: 'WAVBL', label: 'Warmup', value: 'WAVBL'}
        ],
        validations: [
        ]
      },
      {
        name: 'agentName',
        label: 'Agent Name:',
        type: 'dropdown',
        inputType: '',
        placeholder: '',
        options: [
        ],
        validations: [
        ]
      }
    ],
    sheetConfig: {
      applicationName: 'MTS',
      spreadSheetId: '1uR5zN_pjVqgtR6it0NUsxzeKL3bTSf9goWNPsGSiuJQ',
      developerKey: 'AIzaSyDGkonFd0xLxdQbNDA3oPn1I6AOowtPB6w',
      writer: {
        sheetName: 'Virseker',
        url: 'https://mts.motloungtech.africa/Writers/writer-template.php'
      },
      reader: {
        sheetName: 'agent-names',
        url: 'https://mts.motloungtech.africa/Readers/readnames-template.php',
        set: true
      }
    }
  }]
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
