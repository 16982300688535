import { Component, OnInit } from '@angular/core';
import { GoogleApiIntegrationService } from '../google-api-integration.service';
import * as $ from 'jquery';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'resubmitter',
  templateUrl: './resubmitter.component.html',
  styleUrls: ['./resubmitter.component.scss']
})
export class ResubmitterComponent implements OnInit {
  API_URL = environment.afbSubmitterUrl;

  constructor(private google: GoogleApiIntegrationService) { }

  ngOnInit(): void {
    let prevPhone;
    let currentPhone
    this.google.readLeads().subscribe((leads) => {
      console.log(leads);

      leads.map((lead: any[], index: number) => {

        if (index != 0) {
          this.submitLead(
            this.API_URL,
            lead[3],
            lead[2],
            lead[0],
            lead[4],
            lead[1],
            'CRE'
          );
          setTimeout(() => {
            console.log('waiting...')
          }, 2000)
        }
      })
    })
  }

  submitLead(url, email, telephone, name, idNumber, surname, productCode) {
    $.post(url, {
      Email: email,
      CellNumber: telephone,
      FirstName: name,
      IDNumber: idNumber,
      Initials: name.substring(0, 1),
      Surname: surname,
      ProductCode: productCode
    }, (response) => {
      console.log(response);
    })
  }

}
