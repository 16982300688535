import { Component, OnInit } from '@angular/core';
import { MenuItemModel } from './MenuItemModel';
import { UserService } from './Services/user.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PwThemeingService } from '@kaleva-media/purple-water-next';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  navigationIsVisible: boolean = true;
  availebleMenuOptions: MenuItemModel[] = [];
  agentMenuOptions: MenuItemModel[] = [];
  managerMenuOptions: MenuItemModel[] = [];
  userType: string;
  userid: any; //check type
  menuActionsVisible: boolean = false;
  nestedDataSource: MatTreeNestedDataSource<MenuItemModel> = new MatTreeNestedDataSource<MenuItemModel>();
  nestedTreeControl: NestedTreeControl<MenuItemModel> = new NestedTreeControl<MenuItemModel>(this.getChildren);
  devModeParam: string;

  constructor(
    private userService: UserService,
    private router: Router,
    private snackBar: MatSnackBar,
    private theme: PwThemeingService,
    private activatedRoute: ActivatedRoute) {

    this.nestedDataSource.data = [
      {
        id: '1',
        label: 'Campaigns',
        url: '',
        hasChildren: true,
        disableRouting: true,
        icon: 'library_books',
        options: [
          // {
          //   label: 'Cartrack',
          //   url: 'cartrack',
          //   isChild: true
          // },
          // {
          //   label: '1 Life',
          //   url: '1life',
          //   isChild: true
          // },
          // {
          //   label: 'Auto and General',
          //   url: 'auto-and-general',
          //   isChild: true
          // },
          // {
          //   label: 'Budget Insurance',
          //   url: 'budget-insurance',
          //   isChild: true
          // },
          // {
          //   label: 'Dial Direct',
          //   url: 'dial-direct',
          //   isChild: true
          // },
          // {
          //   label: 'First for Women',
          //   url: 'first-for-women',
          //   isChild: true
          // },
          // {
          //   label: 'King Price',
          //   url: 'kingprice',
          //   isChild: true
          // },
          {
            label: 'African Bank',
            url: 'african-bank',
            isChild: true
          },
          {
            label: 'African Bank - Funeral',
            url: 'african-bank-funeral',
            isChild: true
          },
          {
            label: 'African Bank - Investments',
            url: 'african-bank-investments',
            isChild: true
          },
          {
            label: 'African Bank - Transactional',
            url: 'african-bank-transactional',
            isChild: true
          },
        ]
      },
      {
        id: '2',
        label: 'Add Campaign',
        url: 'create-campaign',
        icon: 'add_box',
        badge: {
          hasBadge: true,
          badgeLabel: 'Coming soon'
        },
        disabled: true
      },
      {
        id: '3',
        label: 'Dialer',
        url: 'dialer',
        icon: 'phone',
        badge: {
          hasBadge: true,
          badgeLabel: 'Coming soon'
        },
        disabled: true
      },
      {
        id: '4',
        label: 'Payroll Manager',
        url: 'payroll',
        icon: 'payment',
        badge: {
          hasBadge: true,
          badgeLabel: 'Coming soon'
        },
        disabled: true
      },
      {
        id: '5',
        label: 'Invoicing',
        url: 'invoicing',
        icon: 'attach_money',
        badge: {
          hasBadge: true,
          badgeLabel: 'Coming soon'
        },
        disabled: true
      },
      {
        id: '6',
        label: 'Reporting',
        url: 'reporting',
        icon: 'bar_chart',
        badge: {
          hasBadge: true,
          badgeLabel: 'Brand New!',
          badgeType: 'green-badge'
        },
        disabled: true,
        hasChildren: true,
        disableRouting: true,
        options: [
          // {
          //   label: 'Campaign Overview',
          //   url: 'campaign-overview',
          //   isChild: true
          // }
        ]
      },
      {
        id: '7',
        label: 'Messaging',
        url: 'messaging',
        icon: 'drafts',
        badge: {
          hasBadge: true,
          badgeLabel: 'Coming soon'
        },
        disabled: true
      },
      {
        id: '8',
        label: 'Add User',
        url: 'add-user',
        icon: 'supervised_user_circle',
        badge: {
          hasBadge: true,
          badgeLabel: 'Coming soon'
        },
        disabled: true
      },
    ];

    this.nestedTreeControl.expand(this.nestedDataSource.data[0]);

    this.availebleMenuOptions = [
      {
        id: '1', label: 'Campaigns', url: '', icon: 'archive', level: 0, expandable: true, options: [
          { label: 'dial direct', level: 1, expandable: false },
          { label: 'dial direct 2', level: 1, expandable: false }
        ]
      },
      {
        id: '2',
        label: 'Add Campaign',
        url: '/create-campaign',
        icon: 'add',
        badge: {
          hasBadge: true,
          badgeLabel: 'Coming soon'
        },
        disabled: true
      },
      {
        id: '3',
        label: 'Dialer',
        url: '/dialer',
        icon: 'phone',
        badge: {
          hasBadge: true,
          badgeLabel: 'Coming soon'
        },
        disabled: true
      },
      {
        id: '4',
        label: 'Payroll Manager',
        url: '/payroll',
        icon: 'money',
        badge: {
          hasBadge: true,
          badgeLabel: 'Coming soon'
        },
        disabled: true
      },
      {
        id: '5',
        label: 'Invoicing',
        url: '/invoicing',
        icon: 'receipt',
        badge: {
          hasBadge: true,
          badgeLabel: 'Coming soon'
        },
        disabled: true
       },
      {
        id: '6',
        label: 'Reporting',
        url: '/reporting',
        icon: 'cloud',
        badge: {
          hasBadge: true,
          badgeLabel: 'Coming soon'
        },
        disabled: true
      },
      {
        id: '7',
        label: 'Messaging',
        url: '/messaging',
        icon: 'cloud',
        badge: {
          hasBadge: true,
          badgeLabel: 'Coming soon'
        },
        disabled: true
      },
      // {label: 'Add New Batch', url: '/upload-data', icon: 'cloud_upload'},
      // {label: 'Add New Company', url: '/newCompany', icon: 'account_balance'},
      // {label: 'Add New Team', url: '/newTeam', icon: 'emoji_people'},
      // {label: 'Dial New Client', url: '/dialer', icon: 'phone'},
      // {label: 'Batch Summary', url: '/batch-summary', icon: 'insights'},
    ];

    this.managerMenuOptions = [
      { label: 'Add New Batch', url: '/upload-data', icon: 'cloud_upload' },
      { label: 'Add New Team', url: '/newTeam', icon: 'emoji_people' },
      { label: 'Add New User', url: '/create-user', icon: 'person_add' },
      { label: 'Batch Summary', url: '/batch-summary', icon: 'insights' },
      { label: 'My Profile', url: '/my-profile', icon: 'account_circle' },
    ];

    this.agentMenuOptions = [
      // {label: 'Profile', url: '/profile', icon: 'insights'},
      { label: 'Dial New Client', url: '/dialer', icon: 'phone' },
      // {label: 'My Progress', url: '/user-progress', icon: 'trending_up'},
      // {label: 'Script', url: '/script', icon: 'sticky_note_2'},
      // {label: 'My List', url: '/to-do', icon: 'view_list'},
      { label: 'My Profile', url: '/my-profile', icon: 'account_circle' },
    ];
  }

  ngOnInit() {
    if (!environment.production) {
      // enable dev mode with param
      this.activatedRoute.queryParams.subscribe(params => {
        if (params['isDeveloper']) {
          if(params['isDeveloper'] !== 'yes') {
            this.openSnackBar();
          }
        }
      });
    }

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        requestAnimationFrame(() => {
          this.nestedTreeControl.expand(this.nestedDataSource.data[0]);
        });
      }
    });

    let defaultTheme = this.theme.getDefaultTheme();
    this.theme.setTheme(defaultTheme);
  }

  ngAfterViewInit() {
    this.nestedDataSource.data.map((availableOption: MenuItemModel) => {
      if (availableOption.badge) {
        if (availableOption.badge.hasBadge) {
          if (availableOption.badge.badgeType) {
            document.getElementById(availableOption.id).setAttribute(availableOption.badge.badgeType, availableOption.badge.badgeLabel);
          } else {
            document.getElementById(availableOption.id).setAttribute('data-badge', availableOption.badge.badgeLabel);
          }
        }
      }
    });
  }

  openSnackBar() {
    let _snackBar = this.snackBar.open('Redirecting you to official link...', '', {
      duration: 3000,
      verticalPosition: "bottom", // Allowed values are  'top' | 'bottom'
      horizontalPosition: "center" // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    });

    _snackBar.afterDismissed().subscribe(() => {
      window.location.href ='https://atarocol.co.za/leads-application';
    })
  }

  logout() {
    this.userService.logout();
  }

  getChildren(menuItem: any) {
    return menuItem.options;
  }

  nodeHasChildren(index, node) {
    return node.hasChildren;
  }
}
